@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
}
:root{
    --black:#000000;
    --light_dark:#353535;
    --dark_gray:#808080;
    --white:#ffffff; 
    --blue:#3b9fe2;
    --red:#ffffff;
    --green:#19B886;
    --grey:#ECECEC;
    --fontSize12:12px;
}
/* elements */
.container{
    margin-left:20px ;
    margin-right: 20px;
}
.back-button-text{
    display: block;
    margin-bottom: 4px;
    
}

.back-button{
   
    width: 100%;
    display: block;
    text-decoration: none;
    cursor: pointer;
    background-color: var(--blue);
   color: white;
    padding: 8px 0px;
   
}
.back-button img{
    padding-left: 10px;
  
    padding-top: 5px;
}


.back-button a{
    text-decoration: none;
    color: var(--white);
}
.light-green-text{
    font-size: var(--fontSize12);
    font-weight: 400;
    color: var(--green);
}
.tag-button{
    cursor: pointer;
    padding: 10px 45px;
    background-color: var(--gray);
    border-radius: 5px;
    border: none;
    color: var(--white);
    background-color: gray;
}
/* components */
.header{
   background-color: #bef4f2;
    padding: 7px 0;
    width: 100%;
    position:fixed;
}
.mobile-block{
    min-height: 100vh;
    padding-top: 34px;
}
.btn-category{
cursor: pointer;
}
.mobile-block__header {
    
   
    padding: 10px 0; 
    text-align: center; 
    font-size: var(--fontSize18);
    font-weight: 600; 

}
.is-grey{
   background-color: var(--dark_gray);
}
.is-red{
    background-color: var(--red);
 }
.is-green{
    background-color: var(--green);
}
.news-card{
    cursor: pointer;
    padding: 15px 0;
    border-bottom: 1.5px solid var(--grey);
    text-decoration: none;
    display: block;
    color: #000000;
    
}
.news-card__tittle{
    font-size: var(--fontSize12);
    margin-bottom: 8px;
}
.news-card__date,.news-card__category{
    display: block;
    margin-bottom: 8px;
    font-size: var(--fontSize12);
    color: var(--dark_gray);
}
.post-detail-block{
    padding: 30px 0;
}
.post-detail-block .news-card__tittle,.post-detail-block .news-card__date{
    margin-bottom: 15px;
}
.description{
    font-size: var(--fontSize12);
    margin-bottom: 15px;
}
.post-detail-block img{
    width: auto;
    height: auto;
    border-radius: 10px;
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
    margin-bottom: 30px;
}
.author{
    display: block;
    margin-bottom: 30px;
    font-size: var(--fontSize12);
    font-weight: 400;
}
.category-row{
    display: grid ;
    grid-template-columns: 50% 50%;
    justify-content: center;
    gap: 20px;
    padding: 30px 0;

}
.category-item{
    text-decoration: none;
    cursor: pointer;

    padding: 30px;
    color: black;
    text-align: center;
    border-radius: 30px;
    transition:0.3s ease-in-out ;
}
.category-item:hover{
    background-color: var(--dark_gray)
}
.category-item-tittle{
    font-size: var(--fontSize12);
    color: var(--light_dark);
}
.category-item__img, .category-item-tittle{
    display: block;
}
.category-item__img{
    
    width: 48px;
    height: 48px;
    margin: 0 auto;
    margin-bottom: 30px;
}
